<template>
  <Popup :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="payment_component" slot="component">
      <div class="payment_component_loader" v-if="loader">
        <Loading v-if="!paymentError"></Loading>
      </div>
      <div class="payment_component_container" v-else>
        <div v-if="!paymentSuccess">
          <div v-if="paymentError">
            <p class="payment_component_container_paymentError">{{ paymentError }}</p>
          </div>
          <div v-else>
            <p class="payment_component_container_title">{{$t('payment method')}}</p>
            <p class="payment_component_container_subtitle">{{$t('Please select your payment method')}}</p>
            <div class="payment_component_container_methods">
              <div class="payment_component_container_methods_creditCard" @click="showCheckout()">
                <p>{{$t('credit card')}}</p>
                <img src="@/assets/icons/credit_card_logo.png" alt />
              </div>
              <paypal :gatewaydetail="paypalDetail" :paymentDetail="paymentDetail"></paypal>
            </div>
          </div>
        </div>
        <div v-else>
          <p
            class="payment_component_container_paymentSuccess"
          >{{$t('Congratulations')}}! {{ paymentSuccessResponse }}</p>
          <div class="payment_component_container_success_card">
            <img class="tick" src="@/assets/icons/Check1.svg" />
            <p class="plan-title">{{ paymentDetail.detail.planname }}</p>
            <p class="plan-type">{{ paymentDetail.detail.plantype }}</p>
            <p
              class="plan-interval"
            >{{ paymentDetail.detail.planinterval === 'MONTH' ? 30 : 365 }} days {{ paymentDetail.detail.plantag }} plan</p>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapMutations } from "vuex";

export default {
  props: {
    closePopup: {
      type: Function
    },
    paymentDetail: {
      type: Object
    },
  },
  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      loader: true,
      paymentError: null,
      gateways: [],
      paypalDetail: null,
      checkoutDetail: null,
      stcDetail: null,
      paymentSuccess: false,
      isCheckout: false,
    };
  },
  created() {
    

    if (this.paymentDetail.transactionPurpose === "SUBSCRIPTION") {
      this.subscriptionPreCheck();
    }
  },
  mounted() {
    eventBus.$on("precheck-response", response => {
      this.loader = false;
      if (response.reason) {
        this.paymentError = response.reason;
      } else {
        // eventBus.$emit("listGateway");
        this.paypalDetail = this.setUpGatewayDetail("PAYPAL");
      }
    });

    // eventBus.$on("listgateway-response", response => {
    //   console.log("listgateways", response);
    //   if (response.reason) {
    //     this.loader = false;
    //     this.paymentError = response.reason;
    //   } else {
    //     this.gateways = response.data;

    //     this.paypalDetail = this.setUpGatewayDetail("PAYPAL");

        // This code was already commented. Dont get confused with this.
        //check the payment options of plan.
        // let filteredArr = [];
        //payment filtering through gwmode.
        // this.paymentDetail.detail.paymentoptions.forEach(el => {
        //   filteredArr.push(...this.gateways.filter(element => element.gwmode === el));
        // });
        // if (filteredArr.length > 0) {
        //   let index = filteredArr.findIndex(el => { return el.gatewayid === 'TELCOSTCBH'});

        //   if (index > -1) {
        //     this.stcDetail = this.setUpGatewayDetail(filteredArr[index].gatewayid);
        //   } else {
        //     this.paypalDetail = this.setUpGatewayDetail("PAYPAL");
        //   }
        // } else {
        //   this.paypalDetail = this.setUpGatewayDetail("PAYPAL");
        // }
        //Till here.
        
        // this.loader = false;
      // }
    // });

    eventBus.$on("payment-confirmation", response => {
      this.loader = true;
      this.paymentConfirmationCallback(response);
    });

    eventBus.$on("paymentconfirmation-response", response => {
      
      if (response.transactionstatus === "SUCCESS") {
        this.paymentSuccess = true;
        this.paymentSuccessResponse =
          "You have successfully subscribed to a plan";
        this.setNewSubscription(true);
      }
      this.loader = false;
    });
  },
  methods: {
    ...mapMutations(["setNewSubscription"]),
    subscriptionPreCheck() {
      let payload = {
        transactionpurpose: this.paymentDetail.transactionPurpose,
        planid: this.paymentDetail.detail.planid
      };
      eventBus.$emit("subscribe-precheck", payload);
    },
    setUpGatewayDetail(gateway) {
      let index = this.paymentDetail.gateways.findIndex(element => {
        return element.gatewayid === gateway;
      });

      if (index > -1) {
        return this.paymentDetail.gateways[index];
      }
    },
    paymentConfirmationCallback(response) {
      let referencedata = response.referencedata;

      if (response.gatewayid === "PAYPAL") {
        let paymentdata = response.paymentdata;
        referencedata.referencedata.payerID = paymentdata.payerID;
        referencedata.referencedata.orderID = paymentdata.orderID;
      }

      let payload = {
        gatewayid: response.gatewayid,
        params: {
          referencedata: referencedata
        }
      };

      eventBus.$emit("paymentConfirmation", payload);
    },
    showCheckout() {
      this.checkoutDetail = this.setUpGatewayDetail("CHECKOUT");

      let payload = {
        gatewaydetail: this.checkoutDetail,
        paymentDetail: this.paymentDetail
      };
      eventBus.$emit("showCheckout", payload);

      this.closePopup();
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "popup" */ "@/components/Slots/Popup.vue"),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
    paypal: () =>
      import(
        /* webpackChunkName: "paypal" */ "@/components/gateways/paypal.vue"
      ),
    STC: () => import(/* webpackChunkName: "stc" */ "@/components/gateways/STC.vue")
  },
  beforeDestroy() {
    eventBus.$off("precheck-response");
    // eventBus.$off("listgateway-response");
    eventBus.$off("paypal-paymentconfirmation");
    eventBus.$off("checkout-paymentconfirmation");
    eventBus.$off("paymentconfirmation-response");
  }
};
</script>

<style lang='scss' scoped>
@import "@/sass/_variables.scss";
@import "./payment.scss"

</style>